///////////////////////////////////////////////////////////// PRE
import packageJson from '../../../../package.json';

export const environment = {
  brand: 'karibu',
  production: false,
  domain: 'https://testkaribu.navisk.com',
  domainStatics: 'https://webapitestkaribu.navisk.com/',
  apidomain: 'https://webapitestkaribu.navisk.com/api/',
  googlekey: null,
  youtubeList: null,
  recaptchakey: '6LcwZL4eAAAAAPmgxzup1CmpCPM45IjJ1lXye2k5',
  googleSiteVerification: '7thXmbmaTL41JjfSUh-KtDjagJzZSTBsNI5Bw8xEKkk',
  chat: {
    api: null,
    socket: null,
  },
  defaultLang: 'es',
  myMessages: true,
  allowedVideo: false,
  allowedManagerUsers: true,
  version: packageJson.version,
};
